import "./Gallery.css";
import TitlebarBelowImageList from "../../Components/Gallerypage/gallery";


export default function Gallery(){
    return(
        <>
            <TitlebarBelowImageList/>
        </>
    );
}