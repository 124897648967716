import Bottom from '../../Homepage/bottom/Bottom';

export default function About1() {
    return (
        <div className="About">
            <div className="about-nps">
                <div className="col-1" style={{ marginLeft: '10rem', marginTop: '30px', fontSize: '25px' }}>
                    <h1>
                        About <span style={{ color: 'rgb(3, 153, 154)' }}>NPS</span>
                    </h1>
                </div>
                <div
                    className="col-2"
                    style={{
                        marginLeft: '10rem',
                        marginRight: '150px',
                        fontSize: '1rem',
                        fontWeight: 400,
                        lineHeight: '1.5rem',
                        letterSpacing: '0.03125em',
                        textAlign: 'justify',
                    }}
                >
                    <p>
                        Nehru Public School, Moradabad, is a school of excellence, providing an all-round education to students from diverse backgrounds. It is an English medium, co-educational school, affiliated to the Central Board of Secondary Education (CBSE). The school offers a holistic education that focuses on the overall development of the students, giving them an opportunity to explore their potential in various academic, sports, and extracurricular activities. With state-of-the-art infrastructure, world-class facilities, and an experienced faculty, the school has become one of the most sought-after institutions in the city.
                    </p>
                    <br />
                    <p>
                        The school also takes pride in its faculty, which consists of highly qualified and experienced teachers and administrators. All the teachers are well-versed in the latest teaching methods and technologies and strive to impart quality education to the students. The school also has a strong focus on extra-curricular activities such as music, dance, sports, and arts & crafts.
                    </p>
                    <br />
                    <p>
                        Nehru Public School has a strong emphasis on developing the overall personality of its students. It encourages its students to participate in a wide range of extracurricular activities and sports to help them grow and develop as individuals. The school also organizes various seminars, workshops, and field trips to help the students gain knowledge and develop their skills.
                    </p>
                    <br />
                    <p>
                        The school also has a strong emphasis on community service. It organizes various activities and initiatives to help the needy and underprivileged in the city. It also takes part in various national and international events to promote global awareness and understanding. Nehru Public School has a strong commitment to excellence and strives to achieve the best possible results in all areas of education. It has a dedicated support staff and a highly qualified faculty, who are committed to providing the best education and care to the students. The school also has a strong focus on character building and inculcating good values in the students.
                    </p>
                    <br />
                    <p>
                        Nehru Public School, Moradabad is one of the most sought-after educational institutions in India. It offers quality education and modern infrastructure to its students and provides them with an environment that is conducive to learning and personal growth. The school has an impressive record of producing successful and well-rounded individuals, who have gone on to excel in various fields. It is the perfect choice for any student who is looking for a quality education and a bright future.
                    </p>
                </div>
                <div className="col-1" style={{ marginLeft: '10rem', marginTop: '30px', fontSize: '25px' }}>
                    <h1>
                        NPS <span style={{ color: 'rgb(3, 153, 154)' }}>MISSION</span>
                    </h1>
                </div>
                <div className="col-2" style={{fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: '2.5rem',
                    letterSpacing: '0.03125em',
                    textAlign: 'justify', marginLeft: '10rem', marginRight: '150px' }}>
                    <ul>
                        <li>
                            We strive to create a safe and supportive learning environment that encourages students to develop their full potential.
                        </li>
                        <li>
                            We provide students with a broad-based education that prepares them for success in the ever-changing world.
                        </li>
                        <li>
                            Our curriculum is designed to challenge and motivate students to achieve academic excellence and to become responsible citizens.
                        </li>
                        <li>We emphasize the development of self-confidence and leadership capabilities in our students.</li>
                        <li>
                            We strive to ensure that our students gain the skills and knowledge necessary to succeed in the 21st-century workplace.
                        </li>
                        <li>We believe in providing a challenging, stimulating, and engaging curriculum that is tailored to the individual needs of our students.</li>
                        <li>
                            We recognize that each student has unique learning needs and we strive to meet these needs through differentiated and personalized learning experiences.
                        </li>
                    </ul>
                </div>
            </div>
            <Bottom />
        </div>
    );
}
