import './App.css';
import {Route, Routes} from 'react-router-dom'
import Home from "./Pages/home/Home";
import About from "./Pages/about/About";
import Gallery from "./Pages/gallery/Gallery";
import ResponsiveAppBar from "./Components/Navbar/navbar";



function App() {
  return (
      <>
          <ResponsiveAppBar/>
          <Routes>
          <Route exact path="/" element={<Home/>}/>
              <Route exact path="/about" element={<About/>}/>
              <Route exact path="/gallery" element={<Gallery/>}/>
      </Routes>

      </>
  );
}

export default App;
