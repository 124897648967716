import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper } from '@material-ui/core';
import image1 from '../Images/image1.jpg';
import image2 from '../Images/image2.jpg';
import image3 from '../Images/image3.jpg';
import "./Header.css";
import {CssBaseline} from "@mui/material";

// const useStyles = makeStyles(theme => ({
//     root: {
//         flexGrow: 1,
//         textAlign: 'center',
//         marginTop: theme.spacing(4),
//     },
//     paper: {
//         padding: theme.spacing(2),
//         color: theme.palette.text.secondary,
//     },
//     image: {
//         width: '99%',
//         height: 'auto',
//         maxHeight: 400,
//     },
//     buttonContainer: {
//         marginTop: theme.spacing(2),
//     },
//     button: {
//         marginLeft: theme.spacing(2),
//     },
// }));

const images = [image1, image2, image3];

const Header = () => {
    // const classes = useStyles();
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage(prevImage => (prevImage === images.length - 1 ? 0 : prevImage + 1));
        }, 3000); // Change image every 3 seconds

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <CssBaseline>
        <div className="root">
            <Grid container spacing={2}>
                <Grid item xs={12} l={6}>
                    <Paper className="paper">
                        <img src={images[currentImage]} alt="Slideshow" className="image" />
                    </Paper>
                </Grid>
            </Grid>
        </div>
        </CssBaseline>
    );
};

export default Header;
