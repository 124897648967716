import React from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Footer from "../../Footer/footer";
import directorImage from "../Images/director.jpg";
import { Link } from "react-router-dom";
import ReadMoreButton from "../bottom/ReadMoreButton";

const useStyles = makeStyles(() => ({
    bottom: {
        flex: 12,
        position: "relative",
        backgroundColor: "#f9f9f9",
        fontFamily: "Poppins, sans-serif",
        justifyContent: "space-between",
        textAlign: "justify",
    },
    separator: {
        width: "132%",
        height: "2px",
        backgroundColor: "red",
        border: "none",
        margin: "1rem 0",
        padding:'0',
        marginLeft:'-200px',
        display:'block',
    },
    title: {
        textAlign: "center",
        color: "#317773",
        marginTop: "5px",
        marginBottom: "5px",
        fontSize: "xx-large",
    },
    description: {
        fontWeight: 100,
        fontSize: "1.25rem",
        lineHeight: "2rem",
        letterSpacing: "0.0125em",
        textAlign:'center',
    },
    directorContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "15px",
    },
    directorImage: {
        height: "60%",
        width: "25%",
        float: "left",
        display:'flex',
        marginTop:'60px',
        marginRight:'-20rem',
        boxShadow: "2px 2px 10px gray",
        backgroundColor: "white",
    },
    message: {
        fontWeight: 700,
        fontSize: "1.25rem",
        marginLeft:'-100px',
        paddingTop:'100px',
        paddingBottom:'20px',
        display:'flex',
    },
    message2: {
        paddingTop:'5.5rem',
        fontWeight: 100,
        fontSize: "1.2rem",
        lineHeight: "2rem",
        letterSpacing: "0.0125em",
        marginRight: '50px',
        flexWrap: "wrap",
        WebkitLineClamp: 8,
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
    },
    messageTitle: {
        color: "#317773",
        fontWeight: 700,
        fontSize: "x-large",
        float: "left",
        marginLeft: "10px",
    },
    link: {
        fontFamily: "Poppins, sans-serif",
        textDecoration: "none",
        fontSize: "larger",
        color: "#222222",
        overflow: "hidden",
        WebkitLineClamp: 8,
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        marginBottom: "40px",
    },

}));

export default function Bottom() {
    const classes = useStyles();

    return (
        <div className={classes.bottom}>
            <Container>
                <hr role="separator" aria-orientation="horizontal" className={classes.separator} />
                <Typography variant="h3" className={classes.title}>
                    OUR PHILOSOPHY
                </Typography>
                <Typography variant="body1" className={classes.description}>
                    We believe that education should empower students to lead meaningful lives and become active and engaged members of society.
                </Typography>
                <hr role="separator" aria-orientation="horizontal" className={classes.separator} />
                <div className={classes.directorContainer}>
                    <img src={directorImage} className={classes.directorImage} alt="" />
                    <Link to="/about" target='_self' className={classes.link}>
                    <div>
                        <Typography variant="h3" className={classes.messageTitle}>
                            Director's Message
                        </Typography>
                        <Typography variant="p" className={classes.message}>
                            "We are committed to providing our students with an excellent education that promotes creativity, critical thinking, and problem-solving skills. Our teachers are highly qualified and dedicated to helping our students reach their fullest potential. We offer a variety of extracurricular activities and programs to further enrich our students’ learning."
                        </Typography>
                        <Typography variant="span" className={classes.message2}>
                            At Nehru Public School, we understand the importance of making sure that our students feel safe and secure. We take pride in our commitment to safety and take every step possible to ensure the safety of our students and staff. We believe that every student has the right to learn in an environment that is conducive to their success. We focus on providing an education that is both academically rigorous and enjoyable. We strive to provide individualized learning opportunities and support our students through personalized instruction. We are committed to helping our students develop the skills they need to become successful citizens. We offer a variety of extracurricular activities, including sports, clubs, and other activities, to help our students develop socially and emotionally.

                        </Typography>

                    </div>
                    </Link>
                </div>
            </Container>
            <Footer/>
        </div>
    );
}
