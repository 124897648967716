import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const useStyles = makeStyles((theme) => ({
    footer: {
        flex: 12,
        textAlign: "center",
        position: "relative",
        backgroundColor: "#f9f9f9",
        fontFamily: "'Poppins', sans-serif",
        marginTop: 100,
    },
    gMaps: {
        position: "absolute",
        marginLeft: 10,
        marginTop: 80,
        paddingBottom: 10,
    },
    gMapsIframe: {
        marginTop: 10,
        marginLeft: 20,
        border: 10,
        width: 300,
        height: 200,
        cursor: "pointer",
    },
    textCenter: {
        textAlign: "center",
        marginTop: 10,
        fontSize:"17px",
    },
    textSubtitle: {
        marginTop: 10,
    },
    linksContainer: {
        marginTop: 5,
        marginLeft: 340,
        textDecoration:"none",
        listStyle:"none",
    },
    inksTitle: {
        fontWeight: 700,
        fontSize: "20px",
        marginLeft: 390,
        position: "relative",
        marginTop: -198,
    },
    links: {
        textDecoration:"none",
        color: "#222222",
        cursor: "pointer",
        fontSize: "18px",
        marginLeft: 10,
    },
    listItem: {
        marginTop:15,
        textDecoration: "none",
        listStyle: "none",
        marginLeft:370,
    },
    textBold: {
        fontWeight: 700,
        color: "#317773",
        marginLeft:"-78rem",
    },
}));

export default function Footer() {
    const classes = useStyles();

    return (

        <div className={classes.footer}>
            <Card sx={{paddingTop:'-150px'}} >
                <CardContent className="Card1">
            <div className={classes.gMaps}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3495.458099638302!2d78.74916071489814!3d28.82521848234212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390afb8bc682a313%3A0xfc244574cce23b24!2sNehru%20Public%20School%20G.G.I.C!5e0!3m2!1sen!2sin!4v1673356962118!5m2!1sen!2sin"
                    className={classes.gMapsIframe}
                    title="Google Maps"
                ></iframe>
            </div>

            <div className={classes.hello}>
                <div className="row justify-evenly items-center">
                    <h1 className={classes.textBold}>Contact Details</h1>
                    <div className="col-6">
                        <div className="row">
                            <p className={`${classes.textCenter} ${classes.textSubtitle}`}>
                                <br />
                                <br />
                                <b>Phone No:</b>
                                <br />
                                <a href="tel: 8266905376">+91 8266905376</a>
                            </p>
                            <p className={`${classes.textCenter} ${classes.textSubtitle}`}>
                                <b>Address:</b>
                                G.G.I.C Road
                                <br />
                                 Kundanpur
                                <br />
                                Vikas Nagar
                                <br />
                                 Moradabad
                                <br />
                                 Uttar Pradesh 244103
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.linksContainer}>
                <p className={classes.inksTitle}>USEFUL LINKS</p>
                <ul>
                    <li className={classes.listItem}>
                        <Link className={classes.links} target="_top" to="/">
                            HOME
                        </Link>
                    </li>
                    <li className={classes.listItem}>
                        <Link className={classes.links} target="_top" to="/about">
                            ABOUT
                        </Link>
                    </li>
                    <li className={classes.listItem}>
                        <Link className={classes.links} target="_top" to="/gallery">
                            GALLERY
                        </Link>
                    </li>
                    <li className={classes.listItem}>
                        <Link className={classes.links} target="_top" to="/staffzone">
                            STAFF ZONE
                        </Link>
                    </li>
                </ul>
            </div>
                </CardContent>
            </Card>
        </div>
    );
}
