import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Marquee from "react-fast-marquee";
import Blink from 'react-blink-text';
import "./Announcement.css";

// const useStyles = makeStyles(() => ({
//     container: {
//         width: '100%',
//         backgroundColor:' #1976d2',
//         marginTop:'2px',
//     },
//     announcementText: {
//         display: 'inline-block',
//         marginLeft:'5px',
//         marginRight:'5px',
//         whiteSpace: 'nowrap',
//         color:'white',
//         textAlign:'center',
//     },
// }));

function Announcement() {
    // const classes = useStyles();

    return (
        <Box className="container">
            <Marquee gradient={false} speed={60} pauseOnHover={true}>
                <Blink className="blink announcementText" color="Red" text="Important News!" fontSize="20px" />
                <Typography  className="announcementText">
                    <span>Admissions open for batch 2023-24.</span>
                    <span>Results For Final exams will be declared soon.</span>
                </Typography>
                <Typography  className="announcementText">
                    <span>21st July will be Yoga Day.</span>
                    <span>Student Should assemble in the school at the given time</span>
                </Typography>
            </Marquee>
        </Box>
    );
}

export default Announcement;