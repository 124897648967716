import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "./Rightbar.css";

// const useStyles = makeStyles(() => ({
//     rightbar: {
//         display: "flex",
//         flex: 4,
//         marginTop: "-3px",
//         marginLeft: "-10rem",
//         justifyContent: "space-between",
//         textAlign: "justify",
//         marginRight: "5px",
//     },
//     missionHeading: {
//         color: "#f9f9f9",
//         fontWeight:'700',
//     },
//     infoText: {
//         justifyContent: "center",
//         display: "flex",
//         flexWrap: "wrap",
//         paddingRight:'30px',
//         lineHeight:'30px',
//         fontSize:'15px',
//     },
//     link: {
//         fontFamily: "Poppins, sans-serif",
//         textDecoration: "none",
//         fontSize: "larger",
//         color: "#f9f9f9",
//         overflow: "hidden",
//         WebkitLineClamp: 8,
//         textOverflow: "ellipsis",
//         display: "-webkit-box",
//         WebkitBoxOrient: "vertical",
//         marginBottom: "40px",
//     },
//     cardshadow: {
//         boxShadow: '0px 4px 20px  rgba(0, 0, 0, 0.4)', // Customize the shadow properties as needed
//     },
// }));

export default function Rightbar() {
    // const classes = useStyles();

    return (

        <Box className="rightbar">
            <div className="Mission1">
                <Box className="cardshadow2">
                <Card>
                    <CardContent sx={{height:'20rem', backgroundColor:'#1ababb'}}>
                <Typography variant="h4" className="missionHeading2">
                    Mission
                </Typography>
                <Typography variant="p" className="infoText2">
                    <Link to="/about" target='_self' className="link2">
                        The Nehru Public School, Moradabad is a school located in Moradabad, Uttar Pradesh, India. The school was established in 1992 and is affiliated to the Central Board of Secondary Education (CBSE), New Delhi. The school offers education from Nursery to Class X. The school has a team of highly qualified and experienced teachers who guide the students in their studies.
                        The school has a range of activities such as sports, drama, and music. The school also provides various opportunities and activities to develop the skills of the students.
                    </Link>
                </Typography>
                    </CardContent>
                </Card>
                </Box>
            </div>
        </Box>
    );
}
