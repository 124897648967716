import Header from "../../Components/Homepage/header/Header";
import "./Home.css";
import Announcement from "../../Components/Homepage/announcement/Announcement";
import Sidebar from "../../Components/Homepage/sidebar/Sidebar";
import Middlebar from "../../Components/Homepage/middlebar/Middlebar";
import Rightbar from "../../Components/Homepage/rightbar/Rightbar";
import Bottom from "../../Components/Homepage/bottom/Bottom";



export default function Home() {
    return (
        <>
           <Header/>
            <Announcement/>
            <div className="home1">
                <Sidebar/>
                <Middlebar/>
                <Rightbar/>

            </div>
            <div className="home2">
                <Bottom/>
            </div>

        </>
    );
}