import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import "./Sidebar.css";

// const useStyles = makeStyles((theme) => ({
//     sidebarItem: {
//         flex: 4,
//         display: "flex",
//         marginRight: "5rem",
//         paddingRight: 0,
//         marginLeft: "12rem",
//         width: "100%",
//         overflow: "hidden",
//         position: "relative",
//         borderRadius:'5px',
//         marginBottom: theme.spacing(2),
//     },
//     heading: {
//         textAlign: "center",
//         height: 40,
//         width: 500,
//         position: "absolute",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         zIndex: 9999,
//         backgroundColor: '#1ababb',
//         color: 'white',
//         paddingTop: theme.spacing(1),
//     },
//     scrollText: {
//         position: "relative",
//         top: 50,
//         left: 0,
//         height: "calc(100% - 50px)",
//         width: "100%",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "left",
//         animation: "$marquee 10s linear infinite",
//         backgroundColor: "transparent",
//         marginLeft: 1,
//         justifyItems:"center",
//         zIndex: 1,
//     },
//     "@keyframes marquee": {
//         "0%": {
//             transform: "translateY(100%)",
//         },
//         "100%": {
//             transform: "translateY(-100%)",
//         },
//     },
//     cardshadow: {
//         boxShadow: '0px 4px 20px  rgba(0, 0, 0, 0.4)', // Customize the shadow properties as needed
//     },
// }));

export default function Sidebar() {
    // const classes = useStyles();

    return (
        <div className="sidebarItem">
            <Typography variant="h6" component="p" className="heading">
                UPCOMING EVENTS
            </Typography>
            <div className="scrollText">
                <Box className="cardshadow">
                    <Card>
                        <CardContent sx={{height:'auto', backgroundColor:'#1ababb',color:'white'}}>

        <span>
          Example scrolling text goes here. The school has a range of activities such as sports,
          drama and music. The school also provides various opportunities and activities to develop
          the skills of the students.
        </span>
                        </CardContent>
                    </Card>
                </Box>
            </div>
        </div>
    );
}
